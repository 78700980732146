<template>
  <div>
    <!-- Top Bar -->
    <div ref="topbar" class="topbar" v-show="showTopbar" @mouseover="resetAutoHide" @mouseleave="startAutoHide">
      <!-- Control Buttons -->
      <button class="control-btn" @click="$emit('fullscreen')">Fullscreen</button>
      <button class="control-btn" @click="$emit('restart')">Restart</button>
      <button class="control-btn close-btn" @click="hideTopbar">Close Topbar</button>
    </div>

    <!-- Hover Area with Arrow -->
    <div class="hover-area" @mouseover="handleArrowHover" @mouseleave="cancelHoverTimeout" @click="showTopbar = true">
      <div class="arrow-up"></div> <!-- Arrow icon -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopBar',
  data() {
    return {
      showTopbar: true,
      autoHideTimeout: null,
      inactivityDelay: 3000, // Inactivity delay in milliseconds (3 seconds)
      hoverTimeout: null // Timeout for hover delay
    };
  },
  mounted() {
    this.startAutoHide(); // Start the auto-hide timer initially
  },
  beforeUnmount() {
    this.stopAutoHide(); // Clear any timers when the component is unmounted
    this.cancelHoverTimeout(); // Clear hover timeout if necessary
  },
  methods: {
    startAutoHide() {
      this.stopAutoHide(); // Clear any existing timer before starting a new one
      this.autoHideTimeout = setTimeout(() => {
        this.showTopbar = false; // Hide the top bar after inactivity
      }, this.inactivityDelay);
    },
    resetAutoHide() {
      // If the user interacts with the TopBar, reset the auto-hide timer
      this.stopAutoHide(); 
      this.showTopbar = true; // Ensure the top bar stays visible
    },
    stopAutoHide() {
      // Clear the existing auto-hide timeout to prevent multiple timers running
      if (this.autoHideTimeout !== null) {
        clearTimeout(this.autoHideTimeout);
        this.autoHideTimeout = null;
      }
    },
    hideTopbar() {
      this.showTopbar = false; // Explicitly hide the topbar when "Close Topbar" is clicked
    },
    handleArrowHover() {
      // Start a timeout to show the TopBar after 1 second of hovering over the arrow
      this.hoverTimeout = setTimeout(() => {
        this.showTopbar = true;
      }, 1000); // 1 second delay
    },
    cancelHoverTimeout() {
      // Cancel the hover timeout if the user moves the mouse away before 1 second
      if (this.hoverTimeout !== null) {
        clearTimeout(this.hoverTimeout);
        this.hoverTimeout = null;
      }
    }
  }
};
</script>

<style scoped>
.topbar {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px; /* Increased height for better look */
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 1000;
  transition: opacity 0.5s ease, top 0.5s ease;
}

.control-btn {
  background-color: #444;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.control-btn:hover {
  background-color: #666;
}

.close-btn {
  background-color: #e74c3c;
}

.close-btn:hover {
  background-color: #c0392b;
}

/* Hover area to show the top bar */
.hover-area {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: 5px;
  z-index: 999;
  display: flex;
  justify-content: center;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  cursor: pointer;
  animation: bounce 2s infinite;
}

/* Bounce animation for the arrow */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-3px);
  }
}
</style>