<template>
  <iframe :src="mirage_url" frameborder="0" width="100%" height="100%"></iframe>
</template>

<script>
export default {
  name: 'MirageStage',
  props: {
      mirage_url: String 
  },
  data() {
    return {
      client: null,
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style scoped>
iframe {
  border: none;
  width: 100%; 
  height: 100vh; 
}
</style>